import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Team from "../components/Common/Team";
import FunFactsArea from "../components/Common/FunFactsArea";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import FeedbackStyleThree from '../components/Common/FeedbackStyleThree';
import Partner from '../components/MachineLearning/Partner';

import AboutImg from "../assets/images/agency-image/agency-about-img.jpg";

// Shape Images
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";
import ServicesArea from "../components/ITStartup/ServicesArea";
import Newsletter from "../components/Common/Newsletter";

const AboutUs = () => (
    <Layout>
        <SEO title="About Us Page 3" /> 

        <Navbar />

        {/* <PageBanner pageTitle="About Us" /> */}

        <div className="agency-about-area ptb-80">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="agency-about-img">
                            <img src={AboutImg} alt="image" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="agency-about-content">
                            <h2>Cloud Adaption</h2>
                            <div className="bar"></div>

                            <p> We're dedicated to helping executives and senior management drive cloud adoption, align business and technology strategies with goals, manage complex lifecycle events across the range of diversity in IT environments.</p>

                            <p>We help CEOs develop a cloud migration strategy and move their data, images and applications seamlessly to the clouds.We offer a broad range of cloud technology consulting services for the application, data, communications and infrastructure aspects of your IT strategy. With Code of Duty your team can grow collectively.Our dedication to helping companies migrate their IT infrastructure from on-premise data to public cloud technologies has made us the leading provider in Luxembourg and neighbouring countries in Europe. And why shouldn't we be? Our 2 decades' worth of experience of developing bespoke web solutions for public agencies, multinationals, SMEs and freelancers alike has taught would-be clients that there is only one place to turn when it comes to navigating the complexities of every technological shift today—Code Of Duty or rather CODE.</p>

                            <p>Code of Duty is a leading enterprise-level IT service provider, delivering outsourced management solutions in the form of cloud journey management, cloud strategy and implementation, infrastructure as a service (IaaS), security and compliance, identity and access management (IAM), cybersecurity, data visualisation, custom solutions consultancy and much more.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our History</h3>
                                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Who We Are</h3>
                                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>

        <Newsletter />

        <ServicesArea />
        
        <FunFactsArea />

        <div className="pb-80">
            <Partner />
        </div>
        
        <Footer />
    </Layout>
)

export default AboutUs;